.react-dropdown-select-dropdown-handle {
    margin: 0 0 0 5px !important;

    & > svg {
        width: 20px !important;
        height: 20px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
}

.react-dropdown-select-input {
    margin-left: 0px !important;
    font-size: 18px !important;
}

.react-datepicker-wrapper {
    display: flex;
    padding: 0;
    background-color: #cbdad3;
    border-radius: 25px;

    border: 1px solid #295543;

    position: relative;
}

.react-datepicker__input-container {
    background-color: #cbdad3;
    display: flex;
    height: 47px;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    align-items: center;

    .react-datepicker__calendar-icon {
        position: absolute;
        right: 10px;
    }
}

.react-datepicker__view-calendar-icon input {
    background-color: transparent;
    border: none;
    color: #295543;
    font-size: 18px;
    padding: 0px;

    outline: none;

    @media (min-width: 600px) {
        font-size: 12px;
        line-height: 20px;
    }

    @media (min-width: 1280px) {
        font-size: 14px;
        line-height: 24px;
    }

    @media (min-width: 1520px) {
        font-size: 18px;
        line-height: 28px;
    }
}

.react-datepicker-ignore-onclickoutside {
    background-color: transparent;
    color: #295543;
    font-size: 18px;

    border: none;
}
