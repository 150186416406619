.react-paginate-container {
    display: flex;
    flex-direction: row;
}

.react-paginate-previous {
    background-color: #CBDAD3;

    border-radius: 100px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0px 36px;

    list-style-type: none;
}

.react-paginate-previous a {
    text-decoration: none;
    
    font-family: Manrope, sans-serif;
    font-size: 18px;
    font-weight: 500;

    padding: 12px 20px;

    color: #295543;
}

.react-paginator-page-button {
    list-style-type: none;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 5px;
}

.react-paginator-page-button a {
    padding: 12px 20px;

    font-family: Manrope, sans-serif;
    font-size: 18px;
    font-weight: 500;

    color: #295543;
}

.react-paginator-page-button-active {
    background-color: rgba(203, 218, 211, 0.5);

    color: rgba(41, 85, 67, 0.4);
}

.react-paginator-break {
    list-style-type: none;

    display: flex;
    align-items: center;
    justify-content: center;

}